<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div
        v-if="$store.getters.getMobileModel !== 'PDA'"
        class="qrcode-wrap"
        :style="{display: showQrScaner}"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <div class="input-content-wrap">
        <v-row class="pb-0">
          <v-col
            class="pb-0 pt-0"
            :class="{'pr-0': $store.getters.getMobileModel !== 'PDA' }"
            :cols="$store.getters.getMobileModel !== 'PDA' ? 7 : 8"
          >
            <!-- <v-text-field
              id="input-pcode"
              v-model="selectedPCode"
              label="품번"
              placeholder="QR 스캔"
              @keyup.enter.prevent="onEnterPcode"
            /> -->
            <v-autocomplete
              id="input-pcode"
              ref="refPcode"
              v-model="selectedPCode"
              :items="purchaseInfoList"
              label="품번"
              :item-text="getText"
              item-value="품번"
              auto-select-first
              @change="onPCodeChange"
              @keyup.enter.prevent="onEnterPcode"
            />
          </v-col>
          <v-col
            v-if="$store.getters.getMobileModel !== 'PDA'"
            class="pb-0 pt-4 pl-0"
            cols="1"
          >
            <v-icon
              @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
            >
              mdi-camera
            </v-icon>
          </v-col>
          <v-col
            class="pb-0 pt-0"
            cols="4"
          >
            <v-text-field
              id="input-qty"
              v-model="qty"
              autocomplete="off"
              label="출하수량"
              placeholder="수량"
              type="number"
              clearable
              @keyup.enter.prevent="onEnterQty"
            />
          </v-col>
        </v-row>
        <v-row class="pb-0">
          <v-col
            class="pb-0 pt-0"
            cols="8"
          >
            <v-autocomplete
              id="input-company"
              v-model="selectedCompany"
              :items="companyInfoList"
              label="납품처"
              item-text="거래처명"
              item-value="거래처코드"
              auto-select-first
              @change="onCompanyChange"
              @keyup.enter.prevent="onCompanyChange"
            />
          </v-col>
          <v-col
            class="pb-0 pt-0"
            cols="4"
          >
            <v-text-field
              id="input-car-no"
              v-model="carNo"
              label="출하차량"
              autocomplete="off"
              placeholder="차량번호"
              clearable
              type="number"
              @keyup.enter.prevent="onEnterCarNo"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-btn
              width="100%"
              color="primary"
              @click="onClickSave"
            >
              제품 출하 등록
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            cols="12"
          >
            <v-text-field
              :value="selectedGoodsName"
              label="품명"
              autocomplete="off"
              :readonly="true"
              filled
            />
          </v-col>
        </v-row>
      </div>
      <div
        class="data-grid"
        style="margin-top:6px; background-color:white;"
      >
        <dx-data-grid
          ref="refProductionReceiptGrid"
          :data-source="productionReceiptInfo"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="gridHeightSub"
          :focused-row-enabled="true"
          key-expr="발주번호"
          :focused-row-key.sync="focusedRowKey"
          @focused-row-changed="onSelectionChanged"
          @toolbar-preparing="onToolbarPreparing"
          @row-dbl-click="onRowDblClick"
          @row-removed="onRowRemoved"
        >
          <DxColumnFixing :enabled="true" />
          <DxColumn
            :allow-editing="false"
            caption="품번"
            data-field="품번"
            :fixed="false"
            :min-width="120"
          />
          <DxColumn
            :allow-editing="false"
            caption="수량"
            data-field="수량"
            format="#,##0"
            :min-width="70"
          />
          <DxColumn
            :allow-editing="false"
            caption="납품처"
            data-field="거래처명"
            sort-order="asc"
            :min-width="70"
          />
          <DxColumn
            :allow-editing="false"
            caption="출하차량"
            data-field="출하차량"
            :min-width="70"
          />
          <DxColumn
            :allow-editing="false"
            caption="품명"
            data-field="품명"
            :min-width="120"
          />
          <DxSummary>
            <DxTotalItem
              value-format="#,##0"
              show-in-column="품번"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
          />
          <dx-sorting mode="multiple" />
          <template #grid-title>
            <div
              class="toolbar-label"
              style="font-size:1.1rem;margin-left:0px;"
            >
              <b>{{ today }} 출하목록</b>
            </div>
          </template>
        </dx-data-grid>
      </div>
    </v-container>
  </div>
</template>
<script>
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import DxDataGrid, { DxScrolling, DxColumn, DxPaging, DxSorting, DxColumnFixing, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'

export default {
  name: 'ProductionReceipt',
  components: {
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxDataGrid,
    DxColumnFixing,
    DxSummary,
    DxTotalItem,
    QrCodeScanComponent
  },
  data: function () {
    return {
      isSaving: false,
      userInfo: null,
      selectedPCode: null,
      qrKey: null,
      purchaseInfoList: [],
      companyInfoList: [],
      selectedCompany: null,
      selectedGoodsName: null,
      qty: null,
      carNo: null,
      productionReceiptInfo: null,
      gridHeightSub: null,
      focusedRowKey: null,
      today: AppLib.getToday(),
      isUpdateMode: false,
      selectedRow: null,
      // qrcode scan
      showQrScaner: 'none'
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
      return
    }
    this.refreshCompany()
    this.refreshProductionReceipt()
    this.refreshPurchaseWithout직납품()
  },
  mounted () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    this.initInput()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    handleResize () {
      const drawContentHeight = this.$Q('.input-content-wrap').height()
      this.gridHeightSub = this.$Q('html').height() - drawContentHeight - 60
    },
    initInput () {
      setTimeout(() => {
        this.$Q('#input-pcode').focus()
        this.selectedPCode = null
      }, 100)
      if (this.isUpdateMode) {
        this.carNo = null
        this.selectedCompany = null
      }
      this.qty = null
      this.isUpdateMode = false
      this.selectedGoodsName = null
    },
    getText (row) {
      let 차종 = ''
      if (row.차종 !== null && row.차종 !== undefined) {
        차종 = `(${row.차종})`
      }
      return `${row.품번}${차종}`
    },
    async onClickSave () {
      if (this.isSaving) return
      if (this.carNo === null) {
        this.$snotify.error('출하차량 번호를 입력하여 주세요')
        this.$Q('#input-car-no').focus()
        return
      }
      if (this.qty === undefined || this.qty === null || !AppLib.isNumeric(this.qty) || this.qty <= 0) {
        this.$snotify.error('출하수량을 입력하여 주세요')
        this.$Q('#input-qty').focus()
        return
      }
      if (this.selectedPCode === null) {
        this.$snotify.error('제품 품번을 입력하여 주세요')
        this.$Q('#input-pcode').focus()
        return
      }
      if (this.selectedCompany === null) {
        this.$snotify.error('납품처를 선택하여 주세요')
        this.$Q('#input-company').click()
        return
      }
      this.isSaving = true
      let 단가 = 0
      await this.$_sp.runNoEncodeFindProc('spFindAllCostOrSaleByGoodsCodeAndSaleType', { 입출고구분: 2, 품번: this.selectedPCode, 기준일: AppLib.getNow() })
        .then((data) => {
          this.Loading = false
          const 가격정보 = this.$_sp.MakeModel(data)
          console.log(가격정보)
          if (가격정보.length > 0) {
            단가 = 가격정보[0].가격
          } else {
            this.$snotify.info('가격정보가 등록되어있지 않습니다. 추후 수정하여주세요.')
          }
        })
        .catch(error => {
          this.Loading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      let temp = null
      if (this.isUpdateMode === false) {
        temp = {
          발생일자: AppLib.getDeliveryTime(this.$store.getters.getSysParamByKey('재고관리기준시간')),
          입출고구분: this.$_.findWhere(ConstDef.PRODUCTION_INOUT_CODE, { 코드명: '제품출하' }).코드,
          품번: this.selectedPCode,
          입고키: this.qrKey,
          수량: this.qty,
          단가: 단가,
          입고업체: this.selectedCompany,
          출하차량: this.carNo,
          발주번호: AppLib.uuidSequential(),
          createid: this.userInfo.userName,
          updateid: this.userInfo.userName,
          createtime: AppLib.getNow(),
          거래처명: this.$_.findWhere(this.companyInfoList, { 거래처코드: this.selectedCompany }).거래처명,
          품명: this.selectedRow.품명
        }
        this.$_sp.runInsertSqlProc('창고수불', ['거래처명', '품명'], [temp])
          .then((data) => {
            this.$snotify.info('저장 되었습니다.', { timeout: 700 })
            this.focusedRowKey = temp.발주번호
            this.productionReceiptInfo.push(temp)
            this.$refs.refProductionReceiptGrid.instance.refresh()
            this.initInput()
            this.isSaving = false
          })
          .catch(error => {
            this.initInput()
            this.isSaving = false
            if (error.response.status === ConstDef.GENERRAL_ERROR) {
              this.$snotify.error(error.response.data.message)
            } else {
              this.$snotify.error(`에러코드 : ${error.response.status}`)
            }
          })
      } else {
        temp = {
          발주번호: this.selectedRow.발주번호,
          입출고구분: this.$_.findWhere(ConstDef.PRODUCTION_INOUT_CODE, { 코드명: '제품출하' }).코드,
          수량: this.qty,
          단가: 단가,
          입고업체: this.selectedCompany,
          출하차량: this.carNo,
          updateid: this.userInfo.userName,
          updatetime: AppLib.getNow()
        }
        this.$_sp.runUpdateSqlProc('창고수불', ['발주번호', '입출고구분'], null, [temp])
          .then((data) => {
            this.isSaving = false
            this.$snotify.info('저장 되었습니다.', { timeout: 700 })
            this.selectedRow.입고업체 = this.selectedCompany
            this.selectedRow.출하차량 = this.carNo
            this.selectedRow.수량 = this.qty
            this.selectedRow.거래처명 = this.$_.findWhere(this.companyInfoList, { 거래처코드: this.selectedCompany }).거래처명
            this.initInput()
          })
          .catch(error => {
            this.initInput()
            this.isSaving = false
            if (error.response.status === ConstDef.GENERRAL_ERROR) {
              this.$snotify.error(error.response.data.message)
            } else {
              this.$snotify.error(`에러코드 : ${error.response.status}`)
            }
          })
      }
    },
    onCompanyChange (e) {
      if (this.selectedCompany !== null) {
        setTimeout(() => {
          this.$Q('.v-autocomplete__content').css('display', 'none')
          this.$Q('#input-car-no').focus()
        }, 300)
      }
    },
    onEnterCarNo (e) {
      // insert 제품출고
      this.onClickSave()
    },
    onEnterQty (e) {
      if (this.qty !== null) {
        this.$Q('#input-company').click()
      }
    },
    onEnterPcode (e, pCode) {
      let split = null
      if (pCode === undefined) {
        split = this.$Q('#input-pcode').val()
      } else {
        split = pCode
      }
      split = AppLib.base64DecodeSubstring(split).split('/')
      this.selectedPCode = split[0].replace('?', ' ')
      console.log(this.selectedPCode)
      this.selectedRow = this.$store.getters.getGoodsItemByGoodsNo(this.selectedPCode)
      console.log(this.selectedRow)
      if (this.selectedRow === undefined) {
        this.$snotify.info('해당 품목을 검색할 수 없습니다.')
        this.initInput()
        return
      } else if (this.selectedRow.자재유형 === ConstDef.외주직납품) {
        this.$snotify.info('외주 직납품은 외주 직납품 출하 메뉴에서 처리할 수 있습니다..')
        this.initInput()
        return
      }
      this.selectedGoodsName = this.selectedRow.품명
      setTimeout(() => {
        this.$Q('.v-autocomplete__content').css('display', 'none')
        this.$Q('#input-qty').focus()
      }, 10)
    },
    onPCodeChange (goodsNo) {
      this.onEnterPcode(null, goodsNo)
    },
    onPCodeScan (result) {
      this.onEnterPcode(null, result)
    },
    onRowDblClick (row) {
      this.isUpdateMode = true
      this.selectedPCode = row.data.품번
      this.selectedCompany = row.data.입고업체
      this.carNo = row.data.출하차량
      this.qty = row.data.수량
      this.selectedRow = row.data
      this.qrKey = row.data.입고키
      this.selectedGoodsName = row.data.품명
    },
    onRowRemoved (row) {
      this.$_sp.runDeleteSqlProc('창고수불', [{ 발주번호: row.data.발주번호 }])
        .then((data) => {
          this.$snotify.info('삭제 되었습니다.', { timeout: 700 })
          this.initInput()
        })
    },
    onSelectionChanged (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRow = e.row.data
      }
    },
    onToolbarPreparing   (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: 'grid-title'
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'always',
          options: {
            icon: 'edit',
            text: '수정',
            focusStateEnabled: true,
            onClick: (args) => {
              if (this.selectedRow === null) {
                this.$snotify.error('선택된 행이 없습니다')
                return
              }
              this.onRowDblClick({ data: this.selectedRow })
            }
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'always',
          options: {
            icon: 'refresh',
            text: '수정취소',
            focusStateEnabled: true,
            onClick: (args) => {
              this.initInput()
            }
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          locateInMenu: 'always',
          options: {
            icon: 'trash',
            text: '삭제',
            focusStateEnabled: true,
            onClick: (args) => {
              if (this.selectedRow === null) {
                this.$snotify.error('선택된 행이 없습니다')
                return
              }
              const rowIndex = this.$refs.refProductionReceiptGrid.instance.getRowIndexByKey(this.selectedRow.발주번호)
              this.$refs.refProductionReceiptGrid.instance.deleteRow(rowIndex)
            }
          }
        }
      )
    },
    refreshCompany () {
      this.$_sp.runNoEncodeFindSqlProc('거래처정보', { 거래처구분코드: '발주사' })
        .then((data) => {
          this.companyInfoList = this.$_sp.MakeModel(data)
        })
        .catch(error => {
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    refreshPurchaseWithout직납품 () {
      this.purchaseInfoList = this.$store.getters.getGoodsList('ALL')
      console.log(this.purchaseInfoList)
    },
    refreshProductionReceipt () {
      const param = {
        발생일자: AppLib.getToday(),
        입출고구분: this.$_.findWhere(ConstDef.PRODUCTION_INOUT_CODE, { 코드명: '제품출하' }).코드,
        createid: this.userInfo.userName
      }
      this.$_sp.runNoEncodeFindProc('spFindAllPurchaseReceiptByDateAndTypeAndCreateId', param)
        .then((data) => {
          this.productionReceiptInfo = this.$_sp.MakeModel(data)
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">

</style>
